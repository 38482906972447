import React from "react";

import LargeIcons from "../Icons/Large";

const StaticColumns: React.FC = () => {
  return (
    <section className="c-feature-columns uk-section uk-section-large uk-text-center uk-section-muted c-noprint">
      <div className={`uk-container uk-text-center`}>
        <div className="uk-margin-large-bottom">
          <h2 className="uk-h4 uk-h@m uk-h2@l uk-h1@xl">
            The Total Press Solution
          </h2>
          <hr className="uk-divider-small" />
        </div>
        <div className="c-feature-columns__items uk-margin-medium">
          <div
            uk-grid=""
            className={`uk-child-width-1-1 uk-child-width-1-2@m uk-child-width-1-4@l uk-text-center uk-grid-small`}
          >
            <StaticColumnItem
              iconUrl="/icons/icon-clock.svg"
              iconTitle="Clock"
              title="Saves time"
              subtitle="A professional fitting which is quick and simple to install, saving time &amp; money."
            />
            <StaticColumnItem
              iconUrl="/icons/icon-clipboard-check.svg"
              iconTitle="Clipboard check"
              title="Safety"
              subtitle="Fittings undergo rigorous internal testing to ensure they meet Australian standards and piping tolerances. ."
            />
            <StaticColumnItem
              iconUrl="/icons/icon-briefcase.svg"
              iconTitle="Thumbs Up"
              title="More jobs"
              subtitle="Reece supports you with training and certification, as well as expert advice whenever you need it."
            />
            <StaticColumnItem
              iconUrl="/icons/icon-wrench.svg"
              iconTitle="Shield"
              title="More durable"
              subtitle="To ensure optimal performance, your Rothenberger press tools can be serviced through the Reece Tool Service Department."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

interface Props {
  iconUrl: string;
  iconTitle: string;
  title: string;
  subtitle: string;
}

const StaticColumnItem: React.FC<Props> = (props) => {
  const { iconUrl, iconTitle, title, subtitle } = props;

  return (
    <div>
      <div className="c-feature-columns__item uk-flex uk-flex-column uk-height-1-1">
        <div className="c-feature-columns__wrap">
          <div className="uk-inline">
            <LargeIcons
              iconUrl={iconUrl}
              iconTitle={iconTitle}
              iconSize="large"
            />
          </div>
        </div>
        <div className="c-feature-columns__content uk-margin-auto uk-width-1-1 uk-flex uk-flex-column">
          {title && (
            <h2 className="uk-h5 uk-h5@m uk-margin-small c-feature-columns__title-green">
              {title}
            </h2>
          )}
          {subtitle && <p className="uk-margin-small">{subtitle}</p>}
        </div>
      </div>
    </div>
  );
};

export default StaticColumns;
