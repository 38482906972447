import React from "react";
import { Link, withPrefix } from "gatsby";

// components
import AnchorWrapper from "../TextMedia/AnchorWrapper";

// data
import data from "../../../pages/static-pages/about.yaml"

interface Props {
  title: string;
  year: string;
  imageSrc: string;
  imageAlt: string;
  animation: string;
  link: string;
}

const TimeLine: React.FC = () => {
  return (
    <section className="c-timeline uk-section">
      <div className="uk-container">
        <div className="uk-margin-large-bottom uk-text-center">
          <h2 className="uk-h4 uk-h@m uk-h2@l uk-h1@xl">
            110 Years of Innovation
          </h2>
          <hr className="uk-divider-small" />
        </div>
        <div className="uk-width-1-1 uk-width-4-5@m uk-width-1-1@l uk-margin-auto">
          <ol
            className="c-timeline__list uk-padding-remove uk-margin-remove"
            uk-scrollspy="target: .c-timeline__date span; cls: uk-animation-fade; delay: 100"
          >
            {data.timeline.map((item: Props, index: number) => (
              <TimeLineItem
                key={`timeline_item_${index}`}
                title={item.title}
                year={item.year}
                imageSrc={item.imageSrc}
                imageAlt={item.imageAlt}
                animation={item.animation}
                link={item.link}
              />
            ))}
          </ol>
        </div>
      </div>
    </section>
  );
};

const TimeLineItem: React.FC<Props> = (props) => {
  const {
    title,
    year,
    imageSrc,
    imageAlt,
    animation,
    link
  } = props;

  return (
    <li className="c-timeline__list-item uk-flex">
      <div
        className="c-timeline__content uk-flex-1"
        uk-scrollspy={`cls: ${animation}; repeat: true; delay: 300; repeat: false;`}
      >
        <div className="c-timeline__content-wrap uk-flex uk-flex-middle uk-grid-collapse">
          <div className="c-timeline__content-text">
            <div>
              <p>{title}</p>
            </div>
          </div>
          <div className="c-timeline__content-image">
            <div className="uk-display-inline-block uk-border-circle uk-flex uk-text-center uk-flex-middle">
              <AnchorWrapper
                condition={link ? true : false}
                wrapper={(children) => (
                  <Link
                    to={link}
                    className="uk-overflow-hidden uk-animation-toggle"
                  >
                    {children}
                  </Link>
                )}
              >
                <div className="uk-overflow-hidden uk-border-circle">
                  <img
                    className={`uk-margin-auto ${
                      link ? "uk-animation-kenburns" : ""
                    }`}
                    src={withPrefix(imageSrc)}
                    alt={imageAlt}
                  />
                </div>
              </AnchorWrapper>
            </div>
          </div>
        </div>
      </div>
      <div className="c-timeline__date">
        <div className="uk-border-circle uk-flex uk-text-center uk-flex-middle">
          <span className="uk-width-1-1">{year}</span>
        </div>
      </div>
      <div className="c-timeline__space"></div>
    </li>
  );
};

export default TimeLine;
